@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');
@import url('./styles/header.scss');
@import url('./styles/heroSection.scss');
@import url('./styles/aboutMe.scss');
@import url('./styles/skillsSection.scss');
@import url('./styles/projectsShowcase.scss');
@import url('./styles/contactMe.scss');
@import url('./styles/footer.scss');