.contact-me-section__wrapper {
    background-image: url('../assets/images/bg-effect-3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.form-control {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-color: rgb(15 23 42/var(--tw-border-opacity));
    border-style: solid;
    border-width: 1px;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 10px 12px;
    width: 100%;
}

.contact-infos li {
    display: flex;
    padding-bottom: 35px;
    position: relative;
}

.contact-infos h5,
.contact-infos p {
    color: #fff;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

@media (min-width: 768px) {
    .contact-infos p {
        font-size: 20px;
    }
}

.contact-infos h5 {
    --tw-text-opacity: 0.8;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .05em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.contact-infos p {
    --tw-text-opacity: 1;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.contact-infos .icon {
    --tw-text-opacity: 1;
    align-items: center;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
    display: inline-flex;
    font-size: 25px;
    height: 55px;
    justify-content: center;
    width: 55px;
}

.contact-infos .col {
    flex: 1 1;
    padding-left: 15px;
}

.bg-1,
.bg-2 {
    --tw-bg-opacity: 1;
}

.bg-2 {
    background-color: rgb(254 196 71/var(--tw-bg-opacity));
}

.bg-1 {
    background-color: rgb(155 211 208/var(--tw-bg-opacity));
}

.bg-3 {
    background-color: rgb(254 204 181/var(--tw-bg-opacity));
}