@media (min-width: 1024px) {
    .section-heading {
        padding-bottom: 60px;
    }
}

@media (min-width: 768px) {
    .section-heading {
        padding-bottom: 40px;
    }
}

.section-heading {
    padding-bottom: 30px;
}

@media (min-width: 1024px) {
    .section-heading h3 {
        font-size: 50px;
    }
}

@media (min-width: 768px) {
    .section-heading h3 {
        font-size: 40px;
    }
}

.section-heading h3 {
    --tw-text-opacity: 1;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
}


.feature-box-02:hover {
    --tw-shadow: 5px 5px 0 0;
    --tw-shadow-colored: 5px 5px 0 0 var(--tw-shadow-color);
}

.feature-box-02,
.feature-box-02:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.feature-box-02 {
    --tw-border-opacity: 1;
    --tw-shadow: 0 0 0 0;
    --tw-shadow-colored: 0 0 0 0 var(--tw-shadow-color);
    --tw-shadow-color: #0f172a;
    --tw-shadow: var(--tw-shadow-colored);
    align-items: center;
    border-color: rgb(15 23 42/var(--tw-border-opacity));
    border-width: 2px;
    display: flex;
    padding: 15px;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.bg-1,
.bg-2 {
    --tw-bg-opacity: 1;
}

.react-icon {
    color: #61DBFB;
}

.html-icon {
    color: #e34c26;
}

.css-icon {
    color: #264de4;
}

.node-icon {
    color: #68a063;
}

.ember-icon {
    color: #D95941;
}

.javascript-icon {
    color: #F0DB4F;
}

.java-icon {
    color: #0E8AC8;
}

.feature-box-02 .icon,
.feature-box-02 h6 {
    --tw-text-opacity: 1;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
}

.feature-box-02 .icon {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    align-items: center;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-color: rgb(15 23 42/var(--tw-border-opacity));
    border-width: 2px;
    display: flex;
    font-size: 30px;
    height: 50px;
    justify-content: center;
    width: 50px;
}

.feature-box-02,
.feature-box-02:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.feature-box-02 h6 {
    font-size: 16px;
    font-weight: 600;
    padding-left: 15px;
}

.feature-box-02 .icon,
.feature-box-02 h6 {
    --tw-text-opacity: 1;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
}

.skills-image__wrapper {
    background-color: rgb(255 237 213/1);
}

.skills-section__wrapper {
    background-image: url('../assets/images/bg-effect-1.png');
    background-repeat: no-repeat;
    background-size: cover;
}