@import './constants.scss';

.main-header {
    font-weight: 500;
    color: #0F172A;
    background-color: $primary-color;
}

.navbar-menu-item a.active {
    color: $secondary-color;
}

.navbar-menu-item a.active::after {
    color: $secondary-color;
    left: 0 !important;
    right: auto !important;
    width: 100% !important;
}

.navbar-menu-item a:hover {
    color: $secondary-color;
}

.navbar-menu-item a:hover::after {
    color: $secondary-color;
    left: 0 !important;
    right: auto !important;
    width: 100% !important;
}

.rotate-icon {
    transition: transform 0.5s ease-in-out;
}

.rotate-icon.rotate180 {
    transform: rotate(180deg);
}

.main-header li a:after {
    --tw-bg-opacity: 1;
    --tw-content: "";
    background-color: rgb(234 88 12/var(--tw-bg-opacity));
    bottom: 0;
    content: var(--tw-content);
    height: 2px;
    left: auto;
    position: absolute;
    right: 0;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    width: 0;
}

.sleeky-navbar {
    position: fixed;
    background: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(55, 55, 55, .075);
    top: 0;
    z-index: 999;
}

@media (max-width: 1023px) {
    .main-header .navbar-collapse {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255/var(--tw-bg-opacity));
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
    }
}

@media (max-width: 1023px) {
    .main-header .navbar-collapse.menu-open {
        display: block;
    }
}