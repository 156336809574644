* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Jost', sans-serif;
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.px-btn {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 10px 20px;
  text-transform: uppercase;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.px-btn-theme {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: 6px 6px 0 -1px;
  --tw-shadow-colored: 6px 6px 0 -1px var(--tw-shadow-color);
  --tw-shadow-color: #0f172a;
  --tw-shadow: var(--tw-shadow-colored);
  padding: 8px 12px;
  background-color: rgb(234 88 12/var(--tw-bg-opacity));
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #fff;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.px-btn-theme2,
.px-btn-theme2:hover {
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.px-btn-theme2 {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: 6px 6px 0 -1px;
  --tw-shadow-colored: 6px 6px 0 -1px var(--tw-shadow-color);
  --tw-shadow-color: #ca8a04;
  --tw-shadow: var(--tw-shadow-colored);
  background-color: rgb(250 204 21/var(--tw-bg-opacity));
  color: #0f172a;
  color: rgb(15 23 42/var(--tw-text-opacity));
}

.px-btn-theme2:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: 6px 6px 0 -1px;
  --tw-shadow-colored: 6px 6px 0 -1px var(--tw-shadow-color);
  --tw-shadow-color: #facc15;
  --tw-shadow: var(--tw-shadow-colored);
  background-color: rgb(234 88 12/var(--tw-bg-opacity));
  color: #fff;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.px-btn-theme:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: 6px 6px 0 -1px;
  --tw-shadow-colored: 6px 6px 0 -1px var(--tw-shadow-color);
  --tw-shadow-color: #ea580c;
  --tw-shadow: var(--tw-shadow-colored);
  background-color: rgb(15 23 42/var(--tw-bg-opacity));
}