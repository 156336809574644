.hero-section__wrapper:after {
    --tw-rotate: -45deg;
    --tw-bg-opacity: 1;
    --tw-content: "";
    background-color: rgb(255 237 213/var(--tw-bg-opacity));
    border-radius: 50px;
    bottom: 47%;
    content: var(--tw-content);
    left: 48%;
    position: absolute;
    right: 0%;
    top: -19%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}