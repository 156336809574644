.portfolio-box .portfolio-text h6 {
    margin-bottom: 18px;
}

.portfolio-box .portfolio-text h4,
.portfolio-box .portfolio-text h6 span {
    --tw-text-opacity: 1;
    color: #0f172a;
    color: rgb(15 23 42/var(--tw-text-opacity));
}

.portfolio-box .portfolio-text h6 span {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213/var(--tw-bg-opacity));
    border-radius: 30px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: .05em;
    padding: 8px 20px;
    text-transform: uppercase;
}

.portfolio-box .portfolio-text h4 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
}

.portfolio-box .portfolio-text .btn-bar {
    padding-top: 25px;
}

.portfolio-box {
    color: #555;
}